export const staticLinks = {
  asicSearchRegisters: 'https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx?_afrLoop=3003402451040482&_afrWindowMode=0&_adf.ctrl-state=k9qt1hmz4_24',
  privacyPolicy: 'https://static.anility.io/PrivacyPolicyAnilityPtyLtd.pdf',
  userAgreement: 'https://static.anility.io/UserAgreementAnilityPtyLtd.pdf'
} as const

export const onHoldReasonOptions: readonly string[] = [
  'Awaiting RFI - Extension Requested',
  'Entity Non-Responsive',
  'Customer Instructions Required',
  'Extensive Clarifications Required'
] as const

export const stageNames = {
  ordered: 'Ordered',
  orderedFollowUp: 'Ordered (Follow Up)',
  underReview: 'Under Review',
  underReviewClarificationsRequired: 'Under Review (Clarifications Required)',
  inProgress: 'In Progress',
  completed: 'Completed',
  expired: 'Expired',
  onHold: 'On-Hold'
} as const

export const adminReloadStages = {
  input: 'Input',
  request: 'RequestInput',
  response: 'ResponseInput'
}
